<template>
	<div>
		<div class="location-nav">
			<div class="links">
				<div class="name"><span class="fa fa-th-large"></span>Account</div>
				<router-link to="/business/locations" :class="$route.name === 'business-locations' ? 'active' : null">Locations</router-link>
				<router-link to="/business/items" :class="$route.name === 'business-items' ? 'active' : null">Manage Items</router-link>
			</div>
		</div>

		<router-view v-bind="$props"></router-view>
	</div>
</template>

<script>

export default {

	name: 'BusinessDashboardPage',

};

</script>
